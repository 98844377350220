import { cn } from "@/utils/helpers"
import { Popover, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { usePopper } from "react-popper"

interface IPopoverGeneric {
  button: JSX.Element
  children:
    | JSX.Element
    | (({ open, close }: { open: boolean; close(): void }) => JSX.Element)
  panelClassName?: string
}

export default function PopoverGeneric({
  button,
  panelClassName,
  children,
}: IPopoverGeneric) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement)

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="w-full focus:outline-0 focus:ring-0"
          >
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={cn(
                "absolute z-50 mt-3 transform bg-white px-4 sm:px-0",
                panelClassName,
              )}
            >
              {typeof children === "function"
                ? children({ open, close })
                : children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
