import Avatar from "@/components/avatar"
import { parseName } from "@/utils/helpers"
import { ICell } from "../Cell"

export const TalentsCell = ({ value }: ICell) => {
  return (
    <div className="flex">
      {value?.map((t, i) => (
        <Avatar
          key={`${i}-${t.uid}`}
          email={t?.email}
          tooltip={parseName(t)}
          spacing_size={20}
          size={36}
        />
      ))}
    </div>
  )
}
