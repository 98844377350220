import Avatar from "@/components/avatar"
import { parseName } from "@/utils/helpers"
import { ICell } from "../Cell"

export const ResponsiblesCell = ({ value }: ICell) => {
  if (!Array.isArray(value)) {
    return <p className="text-xs text-gray-300">Invalid data</p>
  }
  return (
    <div className="flex">
      {value?.map((r, idx: number) => (
        <Avatar
          key={idx}
          size={36}
          border={true}
          email={r.email}
          tooltip={parseName(r)}
        />
      ))}
    </div>
  )
}
