import { IFile } from "@/features/storage/types"
import { DropdownGeneric } from "@/shared/components/fields"
import { downloadFile } from "@/utils/api/storage"
import {
  faChevronDown,
  faCloudDownload,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ICell } from "../Cell"

export const AttachmentsCell = ({ value }: ICell) => {
  if (Array.isArray(value) || typeof value?.[0]?.filename !== "string") {
    return <p className="text-xs text-gray-300">Invalid data</p>
  }

  if (!value || !value.length) {
    return "No attachments"
  }

  return (
    <div className="w-fit">
      <DropdownGeneric
        collection={value as IFile[]}
        renderItem={({ item }) => (
          <button
            onClick={() => downloadFile(item.uid)}
            className="m-1 flex items-center gap-2 rounded-md p-2 hover:bg-neutral-100"
          >
            <FontAwesomeIcon icon={faCloudDownload} />
            <p>{item.filename}</p>
          </button>
        )}
        button={
          <div className="flex items-center gap-2">
            <p>See attachments</p>
            <span>
              <FontAwesomeIcon icon={faChevronDown} />
            </span>
          </div>
        }
      />
    </div>
  )
}
