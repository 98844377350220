import dayjs from "dayjs"
import { ICell } from "../Cell"
import { isNaN } from "lodash"

export const DaysRemainingCell = (props: ICell) => {
  if (props.column.daysremainingdataindex === undefined) {
    return <p className="text-xs text-gray-300">No date column selected</p>
  }

  const date = props.record[props.column.daysremainingdataindex]
  let remainingDays = dayjs(date).diff(dayjs(), "days")

  if (date === undefined || remainingDays === 0 || isNaN(remainingDays)) {
    return <p className="text-xs text-gray-300">No date selected</p>
  } else if (remainingDays < 0) {
    return <p className="font-bold text-red-400">Overdue</p>
  } else if (remainingDays < 7) {
    return <p className="text-red-400">{remainingDays} dage</p>
  } else if (remainingDays >= 7 && remainingDays < 14) {
    return <p className="text-orange-400">{remainingDays} dage</p>
  } else {
    return <p className="text-green-400">{remainingDays} dage</p>
  }
}
