import Avatar from "@/components/avatar"
import { IUser, IUserProfiles } from "@/features/people/types"
import {
  CONTACT_PERSONS_APP,
  EMPLOYEE_APP,
  TALENTS_APP,
  postAppData,
} from "@/utils/api/apps"
import { cn, parseName } from "@/utils/helpers"
import {
  faPhone,
  faStars,
  faUserTie,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Popover } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "sonner"
import { ICell } from "../../Cell"

const filterTypes = ["is_employee", "is_talent", "is_contact"]

const filterIconMap = {
  is_employee: faUserTie,
  is_talent: faStars,
  is_contact: faPhone,
}

export const UserFilterCell = ({ record }: ICell) => {
  const [user, setUser] = useState<IUser>(record)

  useEffect(() => {
    setUser(record)
  }, [record])

  const toggleProfile = useCallback(
    async (type: IUserProfiles[0]) => {
      const clone = [...(user?.profiles ?? [])] as IUserProfiles
      const index = clone.indexOf(type)
      if (index === -1) {
        clone.push(type)
      } else {
        clone.splice(index, 1)
      }

      setUser({ ...user, profiles: clone })

      try {
        switch (type) {
          case "is_employee":
            await postAppData(EMPLOYEE_APP, { user: user.uid })
            break
          case "is_talent":
            await postAppData(TALENTS_APP, { user: user.uid })
            break
          case "is_contact":
            await postAppData(CONTACT_PERSONS_APP, { user: user.uid })
            break
        }
      } catch (error) {
        console.error("Failed to update user profile", error)
        toast.error("Something went wrong, try again or contact support")
        setUser(record)
      }
    },
    [record, user],
  )

  const content = useMemo(() => {
    return (
      <>
        <Avatar
          email={user.email}
          border={false}
          tooltip={parseName(user)}
          size={48}
        />
        <div className="flex gap-2">
          {filterTypes.map((profile: IUserProfiles[0]) => {
            const profileActivate = user.profiles.includes(profile)
            return (
              <Popover
                key={user.uid + profile}
                content={
                  <div className="text-xs">
                    {profileActivate ? "Unmark" : "Mark"} as{" "}
                    <span className="font-bold capitalize">
                      {profile.replace("is_", "")}
                    </span>
                  </div>
                }
              >
                <div
                  className={cn(
                    "border-primary text-color-primary flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-dashed",
                    profileActivate &&
                      "border-secondary text-secondary border-solid",
                  )}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    toggleProfile(profile)
                  }}
                >
                  <FontAwesomeIcon size={"sm"} icon={filterIconMap[profile]} />
                </div>
              </Popover>
            )
          })}
        </div>
      </>
    )
  }, [user, toggleProfile])

  if (user.deleted) {
    return <div className="text-red-500">Deleted</div>
  }

  return <div className="flex items-center gap-2">{content}</div>
}
