import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { getProfileLink } from "@/utils/cvpartner"
import { useUser } from "hooks/cvpartner/useUser"
import { useEffect } from "react"
import { ICell } from "../../Cell"

export const ProfilLinkCell = (props: ICell) => {
  const cvpId = props.record?.cvp_id
  const { user, isLoading, error } = useUser(
    props.record?.type === "INTERNAL" ? cvpId : null,
  )
  const link = getProfileLink(user)

  useEffect(() => {
    const a = document.getElementById("cv_profile")
    a?.addEventListener("click", handleLinkClick)
    return () => {
      a?.removeEventListener("click", handleLinkClick)
    }
  }, [])

  const handleLinkClick = (event: any) => {
    event.stopPropagation()
  }

  if ((error || !link) && !isLoading) {
    return <p className="text-gray-200">No link</p>
  }

  if (isLoading) {
    return <Skeleton className="h-4 w-16" />
  }

  return (
    <a
      id="cv_profile"
      href={link}
      target="_blank"
      rel="noreferrer"
      className="cursor-pointer underline hover:text-black hover:underline"
    >
      View profile
    </a>
  )
}
