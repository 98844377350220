import { BasicButton } from "@/components/buttons/BasicButton"
import Modal from "@/components/modal/Modal"
import { IPricing } from "@/features/candidateflow/types"
import { PricingTable } from "@/features/pricings/components"
import { getDefaultPricings } from "@/utils/api/pricings"
import { useEffect, useState } from "react"
import { ICell } from "../Cell"

export const PricingCell = ({ record }: ICell) => {
  const [pricings, setPricings] = useState<IPricing[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    fetchPricings()
  }, [record.uid])

  const fetchPricings = async () => {
    const pricings = await getDefaultPricings(record.uid)
    setPricings(pricings)
  }

  return (
    <>
      <Modal open={showModal}>
        <>
          <div className="min-w-[100vh]">
            <PricingTable />
          </div>
          <BasicButton
            onClick={() => setShowModal(false)}
            className="mt-4 w-full"
          >
            Close
          </BasicButton>
        </>
      </Modal>
      <BasicButton
        onClick={(e) => {
          e.stopPropagation()
          setShowModal(true)
        }}
      >
        See pricings
      </BasicButton>
    </>
  )
}
