import { RenderNA } from "@/shared/components/items/RenderNA"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { Popover } from "antd"
import { ICell } from "../Cell"

export const CustomerCell = ({ value }: ICell) => {
  if (!value) {
    return (
      <div className="flex w-12 items-center justify-center">
        <RenderNA />
      </div>
    )
  }

  return (
    <div className="w-12">
      <Popover
        content={value.name}
        overlayClassName="custom-popover"
        placement="top"
      >
        <div className="flex items-center">
          <ImageWithFallback
            src={ORGANISATION_AVATERS(value.uid)}
            width={0}
            height={0}
            alt="Customer"
          />
        </div>
      </Popover>
    </div>
  )
}
