import PopoverGeneric from "@/shared/components/fields/PopoverGeneric"
import dayjs from "dayjs"
import { ICell } from "../Cell"

export const DateCell = (props: ICell) => {
  const format = props.column?.format ?? "DD/MM/YYYY"

  if (!props.value) {
    return <p className="text-xs text-gray-300">No date selected</p>
  }

  if (!dayjs(props.value).isValid() || !dayjs(props.value?.[0]).isValid()) {
    return <p className="text-xs text-gray-300">Invalid data</p>
  }

  if (Array.isArray(props.value) && Array.isArray(props.value[0])) {
    return (
      <PopoverGeneric
        button={<p>See multiple dates</p>}
        panelClassName="shadow-xl rounded-md"
      >
        <div className="space-y-2 p-2">
          {props.value.map((v, i) => (
            <div key={i}>
              {v.map((d) => dayjs(d).format(format)).join(" - ")}
            </div>
          ))}
        </div>
      </PopoverGeneric>
    )
  }
  if (Array.isArray(props.value)) {
    return props.value.map((v) => dayjs(v).format(format)).join(" - ")
  }
  return dayjs(props.value).format(format)
}
