import { AllocationPill } from "@/features/timeline/components/TrackElements"
import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import { IDate } from "@/types/glossary"
import { fetcher } from "@/utils/api"
import { getAllocationDates, isWithinRange } from "@/utils/helpers"
import { useMemo } from "react"
import useSWR from "swr"
import { ICell } from "../../Cell"

export const AllocationCell = ({ record }: ICell) => {
  const {
    data: talents,
    isLoading,
    error,
  } = useSWR(`/users/${record.uid}/talents`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const startOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1,
  )
  const endOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
  )

  const calculateAllocations = (): {
    hired: number
    potential: number
    extension: number
  } => {
    return talents?.reduce(
      (acc, t) => {
        const periods: IDate[] = getAllocationDates(t?._dates)
        periods.map((p) => {
          if (
            isWithinRange(p.start, p.end, startOfMonth, endOfMonth) &&
            ["hired", "potential", "extension"].includes(p?.data?.status)
          ) {
            acc[p?.data?.status] += isNaN(Number(p?.data?.allocation))
              ? 0
              : Number(p?.data?.allocation)
          }
        })
        return acc
      },
      { hired: 0, potential: 0, extension: 0 },
    ) as { hired: number; potential: number; extension: number }
  }

  const allocations = useMemo(() => {
    return calculateAllocations()
  }, [talents])

  if ((error || !talents) && !isLoading) {
    return <p className="text-gray-200">No allocation</p>
  }

  if (isLoading) {
    return (
      <div className="flex items-center gap-2">
        {Array.from({ length: 3 }).map((_, i) => (
          <Skeleton key={i} className="h-4 w-20" />
        ))}
      </div>
    )
  }

  return (
    <div className="flex items-center gap-2">
      {Object.entries(allocations).map(([key, value]) => (
        <AllocationPill key={key} status={key} allocation={value} />
      ))}
    </div>
  )
}
